body,
#app,
.wrapper {
    height: 100%;
    padding: 0px !important;
}

.content-page {
    display: flex;
    height: 100%;
    margin-top: $navbarHeight;
    margin-left: $sidebarWidth;
    padding-top: 20px;
    background-color: #e9ecef;

    @media (max-width: $screen-sm) {
        margin-left: $sidebarWidthMobile;
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .page-title-box {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
        }

        .breadcrumb {
            @media (max-width: $screen-sm) {
                display: none;
            }

            .breadcrumb-item {
                font-size: 14px;

                a {
                    text-decoration: none;
                    color: $grey;
                }

                &.active {
                    color: #adb5bd;
                    cursor: default;
                }

                & + .breadcrumb-item {
                    &:before {
                        content: '>';
                        font-size: 10px;
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                }
            }
        }
    }
}
