.nav {
    &.nav-pills {
        .nav-item {
            background-color: $lightGrey;
            margin: 0 5px;

            a {
                color: gray;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;

                &.active {
                    background-color: $blue;
                    color: white;
                }
            }

            &.error-box {
                border-radius: 0.25rem;
                background-color: $invalid;

                a {
                    color: white;

                    &.active {
                        color: white;
                    }
                }
            }
        }
    }
}

.tabs-actions {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-around;
    padding: 0;
}
