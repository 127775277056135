.colored-block{
  display: block;
  width: 20px;
  height: 20px;
}

.overflowed-list{
  max-height: 300px;
  overflow: auto;
}

.cursor-pointer{
  cursor: pointer !important;
}
