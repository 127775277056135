.action-button {
    color: $grey;
    border: none;
    outline: none;
    background: none;

    &:hover {
        color: $blue;
    }
}

.is-invalid {
    .multiselect__tags {
        border-color: #dc3545;
    }
}

.dz-image {
    img {
        max-width: 200px;
    }
}
