.error-message {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #f1556c;
}


.size-none .dz-size > span {
  display: none;
}

#variant_image_dropzone.dropzone, #product_image_dropzone.dropzone {
  border: 2px dashed;
  background: white;
}

#variant_image_dropzone.dropzone:hover, #product_image_dropzone.dropzone:hover {
  background-size: 30px 30px;
  background-image: -webkit-linear-gradient(135deg, #F6F6F6 25%, transparent 25%, transparent 50%, #F6F6F6 50%, #F6F6F6 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, #F6F6F6 25%, transparent 25%, transparent 50%, #F6F6F6 50%, #F6F6F6 75%, transparent 75%, transparent);
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}

#variant_image_dropzone.dropzone img, #product_image_dropzone.dropzone img {
  object-fit: cover !important;
}

.clickable-row {
  cursor: pointer;
}

.clickable-row:hover {
  background-color: #ebf3f7 !important;
}

@-webkit-keyframes stripes {
  from {
    background-position: 0 0
  }
  to {
    background-position: 60px 30px
  }
}

@keyframes stripes {
  from {
    background-position: 0 0
  }
  to {
    background-position: 60px 30px
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg)
  }
  100% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0)
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg)
  }
  100% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0)
  }
}
